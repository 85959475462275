/* eslint-disable max-len */

const treeAgeContentEn = [
  {
      minDays: 0,
      title: 'Cuttings',
      text: 'Your saplings are cut from mother trees: trees of \'elite genetics\'. These are trees that have been selected from nature for their root growth, drought resistance and blossom production. This is important, because blossoms transform into nuts.',
  },
  {
      minDays: 2,
      title: 'Containers',
      text: 'Your cuttings are placed in containers with ample nutrition and growing space. This ensures that a sufficient amount of oxygen reaches the cuttings.',
  },
  {
      minDays: 3,
      title: 'Potting',
      text: 'The cuttings are potted by hand. This work is done by women because female motor skills tend to be better than that of males. This decreases the chance of damage to the cuttings.',
  },
  {
      minDays: 4,
      title: 'The first pot',
      text: 'Your potted cuttings are placed in a greenhouse for 4 to 6 weeks. In the greenhouse the cuttings are watered with a misty rain every 10 seconds. In combination with the local warm weather, this creates ideal conditions for the saplings to develop.',
  },
  {
      minDays: 6,
      title: 'Shooting roots',
      text: 'After 4 to 6 weeks in the greenhouse, the saplings reach full strength before getting transferred to larger pots. In these pots, the roots get more space to develop. More space equals more roots, more nutrients and faster growth.',
  },
  {
      minDays: 42,
      title: 'Outside',
      text: 'In the new pot, the saplings are placed under a sunscreen in the open air. This allows the trees to get used to the weather as they grow to adulthood.',
  },
  {
      minDays: 120,
      title: 'Rustication',
      text: 'Shortly after repotting the sapling into larger pots, your trees are removed from under the shade cloths and placed in open air. From this moment on, the trees are only watered when needed and no longer receive further care.',
  },
  {
      minDays: 180,
      title: 'Outgrowth',
      text: 'In 6 to 9 months your saplings will grow into adolescent trees. The photo shows trees that have adapted to the local climate and are ready to be planted in the ground.',
  },
  {
    minDays: 210,
    title: 'Land preparation',
    text: 'Just before your saplings are planted, the land is prepared to create optimal growing conditions for the trees. The Dutch ‘Polder’ system is used for water management.',
  },
  {
    minDays: 270,
    title: 'Plants',
    text: 'After 6 to 9 months of caring for your trees, the saplings are planted by hand. In principle, the trees are not cared for anymore, since they’re strong enough to survive on their own. We do, however, carefully monitor them.',
  },
  {
    minDays: 1095,
    title: 'First blossom',
    text: 'When your trees are 3 to 4 years old, the first blossoms appear on the branches. This is a sign that the trees are ripe for their first harvest in the following year.',
  },
  {
    minDays: 1460,
    title: 'Harvest',
    text: 'In year 4 your trees are usually ready for their first harvest. Harvesting is done manually but can also be done automatically. Ripe nuts are harvested without damaging the blossoms. These blossoms will produce the nuts of the following year.',
  },
  {
    minDays: 1490,
    title: 'Processing nuts',
    text: 'After harvesting, your nuts are peeled and pressed. The oil from the nuts is sold to the oil industry and then refined into green fuel. The cake seed that remains after pressing is rich in protein and is sold as sustainable cattle feed to local farmers.',
  },
  {
    minDays: 1825,
    title: 'Maturity',
    text: 'At seven years old, your trees are \'production mature\'. From now on, the trees will produce a stable amount of nuts every year and continue to actively offset CO2. Pongamia trees can produce nuts for decades.',
  },
];

const treeAgeContentNl = [
  {
    minDays: 0,
    title: 'Stekjes',
    text: 'Jouw jonge boompjes worden gestekt van moederbomen: bomen van ‘elite-genetica’. Dit zijn bomen die uit de natuur geselecteerd zijn op eigenschappen als wortelgroei, droogtebestendigheid en bloesemproductie. Dat laatste is belangrijk omdat de bloesems later noten worden.',
  },
  {
    minDays: 2,
    title: 'Potten',
    text: 'Jouw stekjes worden samen met de benodigde voeding geplant in potten van precies het juiste formaat. Met de juiste groeiruimte krijgen de stekjes meer zuurstof. ',
  },
  {
    minDays: 3,
    title: 'Planten',
    text: 'De stekjes worden met de hand opgepot. Dit werk wordt door vrouwen gedaan, omdat hun fijne motoriek over het algemeen beter is dan dat van mannen. Dit verkleint de kans op beschadiging van de stekjes.',
  },
  {
    minDays: 4,
    title: 'De eerste pot',
    text: 'Jouw gepotte stekjes worden 4 tot 6 weken in een kas geplaatst. In de kas worden de stekjes om de 10 seconden door nevel beregend. Deze nevel is een soort mistregen die in combinatie met het lokale warme weer zorgt voor ideale omstandigheden voor de ontwikkeling van de jonge boompjes.',
  },
  {
    minDays: 6,
    title: 'Wortelschieten',
    text: 'Na 4 tot 6 weken in de kas zijn de jonge boompjes op volle sterkte en worden ze overgebracht naar grotere potten. In deze potten krijgen de wortels meer ruimte om zich te ontwikkelen. Meer ruimte betekent meer wortels, meer voedingsstoffen en een snellere groei.',
  },
  {
    minDays: 42,
    title: 'Naar buiten',
    text: 'In de nieuwe pot worden de jonge boompjes onder een zonwerend doek in de open lucht geplaatst. Zo kunnen de bomen wennen aan het weer terwijl ze uitgroeien tot volwassen exemplaren.',
  },
  {
    minDays: 120,
    title: 'Rustificatie',
    text: 'Kort na het verpotten van de jonge boompjes in grotere potten, worden ze onder de schaduwdoeken vandaan gehaald en in de open lucht geplaatst. Vanaf dat moment krijgen de bomen alleen nog water wanneer dat nodig is. Ze ontvangen geen verdere verzorging meer.',
  },
  {
    minDays: 180,
    title: 'Uitgroei',
    text: 'In 6 tot 9 maanden groeien jouw bomen uit tot ‘puberbomen’. Op de foto staan bomen die zich aan het plaatselijke klimaat hebben aangepast en klaar zijn om definitief geplant te worden.',
  },
  {
    minDays: 210,
    title: 'Voorbereiding van het land',
    text: 'Net voordat de jonge boompjes worden geplant, wordt het land voorbereid om optimale groeiomstandigheden voor de bomen te creëren. Voor de waterhuishouding gebruiken we het Nederlandse poldersysteem.',
  },
  {
    minDays: 270,
    title: 'Planten',
    text: 'Na 6 tot 9 maanden verzorging worden de jonge boompjes met de hand geplant. In principe worden de bomen niet meer verzorgd. Ze zijn nu sterk genoeg zijn om op eigen kracht te overleven. We houden ze natuurlijk wel zorgvuldig in de gaten.',
  },
  {
    minDays: 1095,
    title: 'Eerste bloesem',
    text: 'Wanneer uw bomen 3 tot 4 jaar oud zijn, verschijnen de eerste bloesems aan de takken. Dit is een teken dat de bomen rijp zijn voor de eerste oogst in het volgende jaar.',
  },
  {
    minDays: 1460,
    title: 'Oogsten',
    text: 'In jaar 4 zijn jouw bomen zijn meestal klaar voor de eerst oogst. De oogst gebeurt handmatig, maar kan ook automatisch plaatsvinden. Rijpe noten worden geoogst zonder de bloesems te beschadigen. Dat is belangrijk, omdat deze bloesems het aankomende jaar de nieuwe noten produceren.',
  },
  {
    minDays: 1490,
    title: 'Verwerking van noten',
    text: 'Na de oogst worden jouw noten ontdaan van hun schil en geperst. De olie hieruit wordt verkocht aan de olie-industrie om te raffineren tot groene brandstof. De pulp dat overblijft na het persen is rijk aan proteïne en wordt als veevoer verkocht.',
  },
  {
    minDays: 1825,
    title: 'Volwassen',
    text: 'Als jouw bomen zeven jaar oud zijn, zijn ze ‘productie-volwassen’. Vanaf nu zullen de bomen elk jaar een stabiele hoeveelheid noten produceren en actief CO2 blijven compenseren. Pongamiabomen kunnen tientallen jaren noten produceren.',
  },
];

export const treeAgeContent: any = {
  en: treeAgeContentEn,
  nl: treeAgeContentNl,
};

/* eslint-enable max-len */
